import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [
        "sizeCheckbox",
        "colorCheckbox",
        "heightCheckbox",
        "minPriceSlider",
        "maxPriceSlider",
        "minPriceInput",
        "maxPriceInput"
    ]

    connect() {
        this.initializeFromURL()
        this.setupPriceRangeListener()
    }

    initializeFromURL() {
        const urlParams = new URLSearchParams(window.location.search)

        const sizesParam = urlParams.get('filter_size')
        if (sizesParam) {
            const selectedSizes = sizesParam.split(',')
            this.sizeCheckboxTargets.forEach(checkbox => {
                checkbox.checked = selectedSizes.includes(checkbox.value)
            })
        }

        const colorsParam = urlParams.get('filter_color')
        if (colorsParam) {
            const selectedColors = colorsParam.split(',')
            this.colorCheckboxTargets.forEach(checkbox => {
                checkbox.checked = selectedColors.includes(checkbox.value)
            })
        }

        const minPrice = urlParams.get('filter_price_min')
        const maxPrice = urlParams.get('filter_price_max')

        if (minPrice) {
            this.minPriceSliderTarget.value = minPrice
            this.minPriceInputTarget.value = minPrice
        }
        if (maxPrice) {
            this.maxPriceSliderTarget.value = maxPrice
            this.maxPriceInputTarget.value = maxPrice
        }

        const heightsParam = urlParams.get('filter_height')
        if (heightsParam) {
            const selectedHeights = heightsParam.split(',')
            this.heightCheckboxTargets.forEach(checkbox => {
                checkbox.checked = selectedHeights.includes(checkbox.value)
            })
        }
    }

    setupPriceRangeListener() {
        [this.minPriceSliderTarget, this.maxPriceSliderTarget].forEach(input => {
            input.addEventListener('input', (e) => {
                const isMin = e.target === this.minPriceSliderTarget
                this.updatePriceInputs(e.target.value, isMin)
            })

            input.addEventListener('change', () => {
                this.updateFilters()
            })
        });

        [this.minPriceInputTarget, this.maxPriceInputTarget].forEach(input => {
            input.addEventListener('input', (e) => {
                const isMin = e.target === this.minPriceInputTarget
                this.updatePriceSliders(e.target.value, isMin)
            })

            input.addEventListener('change', () => {
                this.updateFilters()
            })
        });
    }

    updatePriceInputs(value, isMin) {
        if (isMin) {
            const maxValue = parseFloat(this.maxPriceInputTarget.value)
            if (parseFloat(value) <= maxValue) {
                this.minPriceInputTarget.value = value
            }
        } else {
            const minValue = parseFloat(this.minPriceInputTarget.value)
            if (parseFloat(value) >= minValue) {
                this.maxPriceInputTarget.value = value
            }
        }
    }

    updatePriceSliders(value, isMin) {
        console.log('updatePriceSliders', value, isMin);

        if (isMin) {
            const maxValue = parseFloat(this.maxPriceSliderTarget.value)
            if (parseFloat(value) <= maxValue) {
                this.minPriceSliderTarget.value = value
            }
        } else {
            const minValue = parseFloat(this.minPriceSliderTarget.value)
            if (parseFloat(value) >= minValue) {
                this.maxPriceSliderTarget.value = value
            }
        }
    }

    updateFilters() {
        const selectedSizes = this.sizeCheckboxTargets
            .filter(checkbox => checkbox.checked)
            .map(checkbox => checkbox.value)

        const selectedColors = this.colorCheckboxTargets
            .filter(checkbox => checkbox.checked)
            .map(checkbox => checkbox.value)

        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)

        if (selectedSizes.length > 0) {
            params.set('filter_size', selectedSizes.join(','))
        } else {
            params.delete('filter_size')
        }

        if (selectedColors.length > 0) {
            params.set('filter_color', selectedColors.join(','))
        } else {
            params.delete('filter_color')
        }

        const minPrice = this.minPriceInputTarget.value
        const maxPrice = this.maxPriceInputTarget.value
        if (minPrice !== this.minPriceInputTarget.min) {
            params.set('filter_price_min', minPrice)
        } else {
            params.delete('filter_price_min')
        }
        if (maxPrice !== this.maxPriceInputTarget.max) {
            params.set('filter_price_max', maxPrice)
        } else {
            params.delete('filter_price_max')
        }

        const selectedHeights = this.heightCheckboxTargets
            .filter(checkbox => checkbox.checked)
            .map(checkbox => checkbox.value)

        if (selectedHeights.length > 0) {
            params.set('filter_height', selectedHeights.join(','))
        } else {
            params.delete('filter_height')
        }

        const newUrl = `${url.pathname}${params.toString() ? '?' + params.toString() : ''}`
        window.history.pushState({}, '', newUrl);

        this.loadFilteredItems(params);
    }

    loadFilteredItems(params) {
        const itemsContainer = document.querySelector('#items');

        const url = `${window.location.pathname}?${params.toString()}`;

        Rails.ajax({
            url: url,
            type: "get",
            success: (data) => {
                itemsContainer.innerHTML = data.body.innerHTML;
            },
            error: (error) => {
                console.error('Error loading filtered items:', error);
            }
        });
    }
}