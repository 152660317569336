import { Controller } from "stimulus"
import * as Modal from "../src/javascripts/modal.js"

export default class extends Controller {

  static targets = [ "overlay", "cartMenuWrapper", "vat", "companyData", "privateData", "invoice_direction", "invoiceDirectionToggle", "invoice_direction_header", "shipping_address", "checkout_payment", "navbarCartItemsCounter", "navbarMobileCartItemsCounter", "cartMenuItems", "cartMenuCheckoutButton", "cartMenuSummary", "cartMenuShippingCost", "cartMenuTotal", "checkoutCartItems", "checkoutSummaryCartItems", "checkoutShipping", "checkoutPayment", "checkoutConfirmation", "checkoutCartNextButton", "checkoutCartFinishButton", "checkoutCartItemsCount", "checkoutShippingCost", "checkoutTotal", "checkoutVat", "checkoutItems", "vouchercode", "checkoutCharges", "checkoutChargesSummary", "signinModal", "itemPrice", "category_items_loading"]

  static values = {
    currencyFormat: String,
    currencyAbbr: String
  }

  initialize() {
    if(this.element.getAttribute("data-checkout-step")) {
      this.currentStep = parseInt(this.element.getAttribute("data-checkout-step"));
    } else {
      this.currentStep = 1;
    }

    this.locale = this.element.getAttribute("data-locale");
    this.country = this.element.getAttribute("data-country");
    if(!this.locale) {
      this.locale = document.querySelector("meta[name='locale']").getAttribute('content');
    }
    if(!this.country) {
      this.country = document.querySelector("meta[name='country']").getAttribute('content');
    }

    this.currency = document.querySelector("meta[name='currency']").getAttribute('content');
  }

  connect() {
    this.steps = 4;
    if(location.href.endsWith('checkout')) {
      if (typeof gtag === "function") {
        let total = document.querySelector("input#checkout_total_with_vat").value;
        let currency = document.querySelector("input#checkout_currency").value;
        gtag('event', 'begin_checkout', {currency: currency, value: total});
      }
    }

    if(this.hasCategory_items_loadingTarget) {
      let that = this;

      var observer = new IntersectionObserver(function(entries) {
      	if(entries[0].isIntersecting === true)
      		that.loadNextItems(entries[0].target.dataset.offset);
      }, { threshold: [0], rootMargin: "200px" });

      observer.observe(this.category_items_loadingTarget);
    }
  }

  loadNextItems(offset) {
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);
    searchParams.set('offset', offset);

    Rails.ajax({
      url: `${url.origin}${url.pathname}?${searchParams.toString()}`,
      type: "get",
      data: "",
      success: function(data) {},
      error: function(data) {}
    })

    this.category_items_loadingTarget.setAttribute('data-offset', parseInt(offset)+50);
  }

  currenciesFormat(value, currency) {
    if(this.currencyFormatValue == '%u %n') {
      return `${this.currencyAbbrValue} ${value}`
    } else {
      return `${value} ${this.currencyAbbrValue}`
    }
  }

  removeTags(str) {
    if((str===null) || (str===''))
      return '';
    else
      str = str.toString();
    return str.replace( /(<([^>]+)>)/ig, '');
  }

  mainImage() {
    let parent = event.target.parentNode;
    if(parent.dataset.mainImg) {
      event.target.src = parent.dataset.mainImg;
      parent.removeAttribute('data-main-img');
    }
  }

  altImage() {
    let parent = event.target.parentNode;
    if(parent.dataset.altImg) {
      parent.setAttribute('data-main-img', event.target.src);
      event.target.src = parent.dataset.altImg;
    }
  }

  toggleCart() {
    this.cartMenuWrapperTarget.classList.toggle('active');
    this.overlayTarget.classList.toggle('active');

    if(this.cartMenuWrapperTarget.classList.contains("active")) {
      if (typeof gtag === "function") {
        gtag('event', 'view_cart', {});
      }
    }
  }

  toggleMobileMenu() {
    document.querySelector("#navbarMain").classList.toggle('is-active');
  }

  addToCart() {
    let that = this;
    let cartController = this;
    let uuid = event.target.dataset.uuid;
    let quantity = event.target.dataset.quantity;
    let product_number = event.target.dataset.product_number;
    let item = I18n.t('item.item');
    let inp;
    if(document.querySelector("h1.title")) {
      item = document.querySelector("h1.title").textContent;
    }
    if(!quantity) {
      let div = event.target.parentNode.parentNode.parentNode;
      inp = div.querySelector("input#item_quantity");
      if(inp) {
        quantity = inp.value;
      }
    }

    if(!quantity || !uuid) {
      event.stopPropagation();
      return
    }

    let json = {
      uuid: uuid,
      quantity: quantity
    }

    Rails.ajax({
      url: `/${this.country.toLowerCase()}-${this.locale}/cart/add`,
      type: "post",
      data: JSON.stringify(json),
      contentType: false,
      processData: false,
      success: function(data) {
        that.updateCart(data);
        let box = document.createElement("div")
        box.setAttribute("class", "box has-background-primary has-text-white")
        box.textContent = I18n.t('cart.messages.added_to_cart', {item: item})
        box.style.position = "absolute";
        box.style.top = "40vh";
        box.style.left = "50%";
        box.style.transform = "translate(-50%, -50%)"
        box.style.width = "600px";
        box.style.maxWidth = "90vw";
        document.querySelector("div.container").appendChild(box);
        main.fadeOut(box, 2000);

        inp.value = inp.getAttribute("min");

        if (typeof gtag === "function") {
          let currency = document.querySelector('meta[name="currency"]').content;
          let price = 0;
          let p = that.itemPriceTarget;
          if(p) {
            price = p.dataset.price
          }

          gtag('event', 'add_to_cart', {currency: currency, value: price*quantity, items: [{'product' : product_number, 'quantity' : quantity}]});
        }
      },
      error: function(data) {
      }
    });
    event.stopPropagation();
  }

  removeFromCart() {
    let cartController = this;
    let uuid = event.target.dataset.uuid;
    Rails.ajax({
      url: `/${this.country.toLowerCase()}-${this.locale}/cart/${uuid}/remove`,
      type: "delete",
      data: "",
      success: function(data) {
        cartController.navbarCartItemsCounterTarget.textContent = data.count;
        cartController.navbarMobileCartItemsCounterTarget.textContent = data.count;
        if(data.count == 0) {
          cartController.navbarCartItemsCounterTarget.classList.add('is-hidden');
          cartController.navbarMobileCartItemsCounterTarget.classList.add('is-hidden');
        }

        let item = cartController.cartMenuItemsTarget.querySelector(`[data-uuid="${uuid}"]`);
        if(item) {
          item.remove();
        }

        if(cartController.hasCheckoutCartItemsTarget) {
          item = cartController.checkoutCartItemsTarget.querySelector(`[data-uuid="${uuid}"]`);
          if(item) {
            item.remove();
          }
        }

        cartController.updateCart(data);
      },
      error: function(data) {}
    });
    event.stopPropagation();
  }

  decreaseQuantity() {
    let cartController = this;
    let uuid = event.target.dataset.uuid;
    let quantity = parseFloat(event.target.dataset.quantity);
    let step = 1;
    if(event.target.dataset.step && event.target.dataset.step != '') {
      step = parseFloat(event.target.dataset.step);
      if(step == 0) {
        step = 1;
      }
    }
    if(quantity > 0) {
      quantity -= step;
    }

    if(quantity == 0) {
      let item = this.checkoutCartItemsTarget.querySelector(`[data-uuid="${uuid}"]`);
      if(item) {
        item.remove();
      }
    }

    let json = {
      uuid: uuid,
      quantity: quantity
    }

    Rails.ajax({
      url: `/${this.country.toLowerCase()}-${this.locale}/cart/update`,
      type: "POST",
      data: JSON.stringify(json),
      contentType: false,
      processData: false,
      success: function(data) {
        cartController.updateCart(data);
      },
      error: function(data) {}
    });
  }

  increaseQuantity() {
    let cartController = this;
    let uuid = event.target.dataset.uuid;
    let quantity = parseFloat(event.target.dataset.quantity);

    let step = 1;
    if(event.target.dataset.step && event.target.dataset.step != '') {
      step = parseFloat(event.target.dataset.step);
      if(step == 0) {
        step = 1;
      }
    }
    if(quantity > 0) {
      quantity += step;
    }

    let json = {
      uuid: uuid,
      quantity: quantity
    }

    Rails.ajax({
      url: `/${this.country.toLowerCase()}-${this.locale}/cart/update`,
      type: "POST",
      data: JSON.stringify(json),
      contentType: false,
      processData: false,
      success: function(data) {
        cartController.updateCart(data);
      },
      error: function(data) {}
    });
  }

  updateCart(data) {
    this.navbarCartItemsCounterTarget.textContent = data.count;
    this.navbarMobileCartItemsCounterTarget.textContent = data.count;
    if(data.count == 0) {
      this.navbarCartItemsCounterTarget.classList.add('is-hidden');
      this.navbarMobileCartItemsCounterTarget.classList.add('is-hidden');
      this.cartMenuCheckoutButtonTarget.classList.add('is-hidden');

      this.cartMenuItemsTarget.innerHTML = `
        <li class="cart-empty">
          <p class="has-text-grey-dark p-3">
            ${I18n.t('cart.is_empty')}
          </p>
        </li>
      `

      if(this.hasCheckoutCartNextButtonTarget) {
        this.checkoutCartNextButtonTarget.disabled = true;
      }
      if(this.hasCheckoutCartItemsTarget) {
        this.checkoutCartItemsTarget.innerHTML = `
          <p class="has-text-grey-dark p-3">
            ${I18n.t('cart.is_empty')}
          </p>
        `
      }
    } else {
      this.navbarCartItemsCounterTarget.classList.remove('is-hidden');
      this.navbarMobileCartItemsCounterTarget.classList.remove('is-hidden');
      this.cartMenuCheckoutButtonTarget.classList.remove('is-hidden');

      let div = this.cartMenuItemsTarget.querySelector("li.cart-empty");
      if(div) {
        div.remove();
      }
    }

    if(data.vouchers && data.vouchers.length == 0) {
      if(document.querySelector('.vouchers')) {
        location.reload();
      }
    }

    if(this.hasCartMenuTotalTarget) {
      this.cartMenuTotalTarget.innerHTML = this.currenciesFormat(data.total_with_vat.toFixed(2), this.currency);
    }
    if(this.hasCartMenuShippingCostTarget) {
      this.cartMenuShippingCostTarget.innerHTML = this.currenciesFormat(data.shipping.toFixed(2), this.currency);
    }

    if(this.hasCheckoutCartItemsCountTarget) {
      this.checkoutCartItemsCountTarget.textContent = data.count;
    }
    if(this.hasCheckoutTotalTarget) {
      this.checkoutTotalTargets.forEach((div, i) => {
        div.innerHTML = this.currenciesFormat(data.total_with_vat.toFixed(2), this.currency);
      });
    }
    if(this.hasCheckoutItemsTarget) {
      this.checkoutItemsTarget.innerHTML = this.currenciesFormat(data.total_items.toFixed(2), this.currency);
    }
    if(this.hasCheckoutVatTarget) {
      this.checkoutVatTarget.innerHTML = this.currenciesFormat(data.vat.toFixed(2), this.currency);
    }
    if(this.hasCheckoutShippingCostTarget) {
      this.checkoutShippingCostTargets.forEach((target, i) => {
        target.innerHTML = this.currenciesFormat(data.shipping.toFixed(2), this.currency);
      });
    }
    if(this.hasCheckoutChargesTarget) {
      if(data.charges) {
        this.checkoutChargesTarget.cells[0].innerHTML = `
          ${I18n.t('checkout.charges')}
          <span class="is-size-7">
            ${data.charges.description}
          </span>
        `

        this.checkoutChargesTarget.cells[1].textContent = this.currenciesFormat(data.charges.charges.toFixed(2), this.currency);

        let strong = this.checkoutChargesSummaryTarget.querySelector('strong');
        strong.innerHTML = `
          ${data.charges.description}
          <span class="is-pulled-right">
            ${this.currenciesFormat(data.charges.charges.toFixed(2), this.currency)}
          </span>
        `
        this.checkoutChargesSummaryTarget.classList.remove('is-hidden');
      } else {
        this.checkoutChargesTarget.cells[1].textContent = '';
        this.checkoutChargesSummaryTarget.classList.add('is-hidden');
      }
    }

    let div;
    data.items.forEach((item, i) => {
      div = this.cartMenuItemsTarget.querySelector(`li[data-uuid="${item.uuid}"]`);
      if(div) {
        let sml = div.querySelector('small');
        if(sml) {
          sml.innerHTML = `${I18n.t('cart.quantity')}: ${item.quantity}`;
        }
        sml = div.querySelector('small.pos-total');
        if(sml) {
          sml.innerHTML = this.currenciesFormat(item.total.toFixed(2), this.currency);
        }
      } else {
        let details = [];
        let span = '';
        if(item.color) {
          details.push(`${I18n.t('item.color')}: ${item.color}`);
        }
        if(item.size) {
          details.push(`${I18n.t('item.size')}: ${item.size}`);
        }
        if(details.length > 0) {
          span = `<span class="is-size-7">${details.join(', ')}</span><br>`;
        }

        let cartItem = document.createElement('li');
        cartItem.classList.add('mb-3');
        cartItem.setAttribute("data-uuid", item.uuid);
        cartItem.innerHTML = `
            <article class="media">
              <figure class="media-left">
                <p class="image is-64x64 is-clipped">
                  <img src="${item.image}" alt="${item.name}" class="is-overlay">
                </p>
              </figure>
              <div class="media-content pt-1">
                <div class="content">
                  <p>
                    <strong>${item.name}
                      <span class="is-pulled-right">
                        <button type="button" class="button is-white is-small" data-uuid="${item.uuid}" data-action="click->cart#removeFromCart">
                          <i class="fas fa-times has-text-danger"></i>
                        </button>
                      </span>
                    </strong>
                    <br>
                    ${span}
                    <small>${I18n.t('cart.quantity')}: ${item.quantity}</small>
                    <small class="is-pulled-right has-text-weight-semibold pos-total">
                      ${this.currenciesFormat(item.total.toFixed(2), this.currency)}
                    </small>
                  </p>
              </div>
            </div>
          </article>
        `
        this.cartMenuItemsTarget.appendChild(cartItem);
      }

      if(this.hasCheckoutCartItemsTarget) {
        div = this.checkoutCartItemsTarget.querySelector(`[data-uuid="${item.uuid}"]`);
        let inp = div.querySelector('input');
        inp.value = item.quantity;

        div.querySelector('button.decrease').setAttribute("data-quantity", item.quantity);
        div.querySelector('button.increase').setAttribute("data-quantity", item.quantity);

        let div_total = div.querySelectorAll("div.price-total");
        div_total.forEach((dt, i) => {
          dt.textContent = this.currenciesFormat(item.total.toFixed(2), this.currency);
        });

        div = this.checkoutSummaryCartItemsTarget.querySelector(`[data-uuid="${item.uuid}"]`);

        let sum_total = div.querySelector("span.is-pulled-right");
        if(sum_total) {
          sum_total.textContent = this.currenciesFormat(item.total.toFixed(2), this.currency);
        }
        let sum_quantity = div.querySelector("span.quantity");
        if(sum_quantity) {
          sum_quantity.textContent = inp.value;
        }
      }
    });
  }

  nextStep() {
    if(this.currentStep == this.steps) {
      return;
    } else {

      if(this.currentStep == 2) {
        let customer = document.querySelector("#data_customer_company");

        let firstname = document.querySelector("#checkout_firstname");
        let lastname = document.querySelector("#checkout_lastname");
        let email = document.querySelector("#checkout_email");
        let email_helper = document.querySelector(".help.email");
        let phone = document.querySelector("#checkout_phone");
        let cem = document.querySelector("#data_cem");
        let cem_helper = document.querySelector(".help.cem");

        let name = document.querySelector("#checkout_name");
        let street = document.querySelector("#checkout_street");
        let zip = document.querySelector("#checkout_zip");
        let location = document.querySelector("#checkout_location");
        let country = document.querySelector("#checkout_country");

        let error = false;
        if(firstname.value.trim() == '') {
          firstname.classList.add('is-danger');
          error = true;
        } else {
          firstname.classList.remove('is-danger');
        }
        if(lastname.value.trim() == '') {
          lastname.classList.add('is-danger');
          error = true;
        } else {
          lastname.classList.remove('is-danger');
        }
        if(email.value.trim() == '' || !this.validateEmail(email.value.trim())) {
          email.classList.add('is-danger');
          email_helper.classList.remove("is-hidden");
          error = true;
        } else {
          email.classList.remove('is-danger');
          email_helper.classList.add("is-hidden");
        }

        if(phone.getAttribute('required')) {
          if(phone.value.trim() == '' || phone.value.length < 6) {
            phone.classList.add('is-danger');
            error = true;
          } else {
            phone.classList.remove('is-danger');
          }
        }

        if(cem) {
          if(cem.value.trim() != '' && !this.validateEmail(email.value.trim())) {
            cem.classList.add('is-danger');
            cem_helper.classList.remove("is-hidden");
            error = true;
          } else {
            cem.classList.remove('is-danger');
            cem_helper.classList.add("is-hidden");
          }
        }

        if(name.value.trim() == '') {
          name.value = `${firstname.value.trim()} ${lastname.value.trim()}`.trim()
        }
        if(street.value.trim() == '') {
          street.classList.add('is-danger');
          error = true;
        } else {
          street.classList.remove('is-danger');
        }
        if(zip.value.trim() == '') {
          zip.classList.add('is-danger');
          error = true;
        } else {
          zip.classList.remove('is-danger');
        }
        if(location.value.trim() == '') {
          location.classList.add('is-danger');
          error = true;
        } else {
          location.classList.remove('is-danger');
        }
        if(country.value.trim() == '') {
          country.parentNode.classList.add('is-danger');
          error = true;
        } else {
          country.parentNode.classList.remove('is-danger');
        }

        if(email.value.trim() == '' || !this.validateEmail(email.value.trim())) {
          email.classList.add('is-danger');
          email_helper.classList.remove("is-hidden");
          error = true;
        } else {
          email.classList.remove('is-danger');
          email_helper.classList.add("is-hidden");
        }

        /*
        if(customer.checked) {
          let taxid = document.querySelector("#data_taxid");
          if(taxid.value.trim() == '') {
            taxid.classList.add('is-danger');
            error = true;
          } else {
            taxid.classList.remove('is-danger');
          }

          let fiscal_code = document.querySelector("#data_fiscal_code");
          if(fiscal_code.value.trim() == '') {
            if(taxid.value.trim() != '') {
              fiscal_code.value = taxid.value;
            } else {
              fiscal_code.classList.add('is-danger');
              error = true;
            }
          } else {
            fiscal_code.classList.remove('is-danger');
          }
        }
        */

        if(this.invoiceDirectionToggleTarget.checked) {
          let invoice_name = document.querySelector("#invoice_name");
          let invoice_street = document.querySelector("#invoice_street");
          let invoice_zip = document.querySelector("#invoice_zip");
          let invoice_location = document.querySelector("#invoice_location");

          if(invoice_name.value.trim() == '') {
            invoice_name.classList.add('is-danger');
            error = true;
          } else {
            invoice_name.classList.remove('is-danger');
          }
          if(invoice_street.value.trim() == '') {
            invoice_street.classList.add('is-danger');
            error = true;
          } else {
            invoice_street.classList.remove('is-danger');
          }
          if(invoice_zip.value.trim() == '') {
            invoice_zip.classList.add('is-danger');
            error = true;
          } else {
            invoice_zip.classList.remove('is-danger');
          }
          if(invoice_location.value.trim() == '') {
            invoice_location.classList.add('is-danger');
            error = true;
          } else {
            invoice_location.classList.remove('is-danger');
          }
        }

        if(error) {
          return;
        }
      }

      this.currentStep++;

      if(this.currentStep > 1) {
        let btnBack = document.querySelector('#btn-back');
        btnBack.classList.remove('is-static');
      }

      this.refreshView();
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  previousStep() {
    if(this.currentStep == 1) {
      return;
    }

    this.currentStep--;

    let currentState = document.querySelector(".steps").children[this.currentStep-1];
    currentState.classList.add('is-active');

    this.refreshView();
  }

  refreshView() {
    let steps = document.querySelector(".steps").children;
    for(var i = 0; i<steps.length; i++) {
      steps[i].classList.remove('is-active');
      if(i+1 == this.currentStep) {
        steps[i].classList.add('is-active');
      }
    }

    let btnNext = document.querySelector('#btn-next');
    let btnFinish = document.querySelector('#btn-confirm');

    if(this.currentStep <= 1) {
      let btnBack = document.querySelector('#btn-back');
      btnBack.classList.add('is-static');
    }

    if(this.currentStep == this.steps) {
      btnNext.classList.add('is-hidden');
      btnFinish.classList.remove('is-hidden');
    } else {
      btnFinish.classList.add('is-hidden');
      btnNext.classList.remove('is-hidden');
    }

    this.checkoutCartItemsTarget.classList.add('is-hidden');
    this.checkoutShippingTarget.classList.add('is-hidden');
    this.checkoutPaymentTarget.classList.add('is-hidden');
    this.checkoutConfirmationTarget.classList.add('is-hidden');

    let company = this.removeTags(document.querySelector("#checkout_company").value);
    let firstname = this.removeTags(document.querySelector("#checkout_firstname").value);
    let lastname = this.removeTags(document.querySelector("#checkout_lastname").value);
    let email = this.removeTags(document.querySelector("#checkout_email").value);

    let address = this.shipping_addressTarget;
    let name = this.removeTags(document.querySelector("#checkout_name").value);
    let street = this.removeTags(document.querySelector("#checkout_street").value);
    let zip = this.removeTags(document.querySelector("#checkout_zip").value);
    let location = this.removeTags(document.querySelector("#checkout_location").value);
    let state = this.removeTags(document.querySelector("#checkout_state").value);
    let country = this.removeTags(document.querySelector("#checkout_country").value);

    address.innerHTML = '';
    if(document.querySelector("#checkout_company").value != '') {
      address.innerHTML += `<b>${company}</b><br>`;
    }
    address.innerHTML += `<b>${firstname} ${lastname}</b>`;
    address.innerHTML += '<br>';
    address.innerHTML += email;
    address.innerHTML += '<br><br>';
    address.innerHTML += '<b>'+name+'</b><br>';
    address.innerHTML += street+'<br>'+zip+' '+location;
    if(state && state != '') {
      address.innerHTML += '<br>'+state;
    }
    if(country && country != '') {
      address.innerHTML += '<br>'+countries.getName(country, this.locale);
    }

    if(this.invoiceDirectionToggleTarget.checked) {
      let invoice_address = document.querySelector("#invoice_address");
      let invoice_name = this.removeTags(document.querySelector("#invoice_name").value);
      let invoice_street = this.removeTags(document.querySelector("#invoice_street").value);
      let invoice_zip = this.removeTags(document.querySelector("#invoice_zip").value);
      let invoice_location = this.removeTags(document.querySelector("#invoice_location").value);
      let invoice_state = this.removeTags(document.querySelector("#invoice_state").value);
      let invoice_country = this.removeTags(document.querySelector("#invoice_country").value);

      invoice_address.innerHTML = '';
      invoice_address.innerHTML += '<b>'+invoice_name+'</b><br>';
      invoice_address.innerHTML += invoice_street+'<br>'+invoice_zip+' '+invoice_location;
      if(invoice_state && invoice_state != '') {
        invoice_address.innerHTML += '<br>'+invoice_state;
      }
      if(invoice_country && invoice_country != '') {
        invoice_address.innerHTML += '<br>'+countries.getName(invoice_country, this.locale);
      }

      invoice_address.classList.remove("is-hidden");
      this.invoice_direction_headerTarget.classList.remove("is-hidden");
    } else {
      invoice_address.classList.add("is-hidden");
      this.invoice_direction_headerTarget.classList.add("is-hidden");
    }

    switch(this.currentStep) {
      case 1:
        this.checkoutCartItemsTarget.classList.remove('is-hidden');
        break;
      case 2:
        this.checkoutShippingTarget.classList.remove('is-hidden');
        break;
      case 3:
        this.checkoutPaymentTarget.classList.remove('is-hidden');
        break;
      case 4:
        this.checkoutConfirmationTarget.classList.remove('is-hidden');
        break;
    }
  }

  setAddress() {
    let addr = event.target.dataset.address;

    let name = event.target.dataset.name;
    let street = event.target.dataset.street;
    let zip = event.target.dataset.zip;
    let location = event.target.dataset.location;
    let state = event.target.dataset.state;
    let country = event.target.dataset.country;

    document.querySelector("#checkout_name").value = name;
    document.querySelector("#checkout_street").value = street;
    document.querySelector("#checkout_zip").value = zip;
    document.querySelector("#checkout_location").value = location;
    document.querySelector("#checkout_state").value = state;
    document.querySelector("#checkout_country").value = country;

    document.querySelector("#checkout_country").dispatchEvent(new Event('change'));
  }

  setInvoiceAddress() {
    let addr = event.target.dataset.address;

    let name = event.target.dataset.name;
    let street = event.target.dataset.street;
    let zip = event.target.dataset.zip;
    let location = event.target.dataset.location;
    let state = event.target.dataset.state;
    let country = event.target.dataset.country;

    document.querySelector("#invoice_name").value = name;
    document.querySelector("#invoice_street").value = street;
    document.querySelector("#invoice_zip").value = zip;
    document.querySelector("#invoice_location").value = location;
    document.querySelector("#invoice_state").value = state;
    document.querySelector("#invoice_country").value = country;
  }

  toggleTermCheck() {
    var btn = document.querySelector('#btn-confirm');
    if(event.target.checked) {
      btn.removeAttribute('disabled', 'true');
    } else {
      btn.setAttribute('disabled', 'true');
    }
  }

  toggleFinishButton() {
    var btn = document.querySelector('#btn-confirm');
    var btnBack = document.querySelector('#btn-back');
    var icon = btn.querySelector("svg");
    if(btn.classList.contains('is-static')) {
      btn.classList.remove('is-warning');
      btn.classList.add('is-primary');
      btn.classList.add('is-static');
      btnBack.classList.remove('is-static');

      icon.classList.remove("fa-spinner","fa-pulse");
      icon.classList.add("fa-check");
    } else {
      btn.classList.add('is-static');
      btnBack.classList.add('is-static');
      btn.classList.remove('is-primary');
      btn.classList.add('is-warning');

      icon.classList.remove("fa-check");
      icon.classList.add("fa-spinner","fa-pulse");

      let radio = document.querySelector('[name="checkout[payment_method]"]:checked')
      if(radio.value != 'advance') {
        var busy = document.querySelector(".modal");

        let sumup;
        try {
          sumup = SumUpCard;
        } catch {

        }

        if(sumup && radio.value != 'paypal') {
          busy.querySelector(".modal-content").classList.add("card")
          busy.querySelector(".modal-content").innerHTML = `
            <div id="sumup-notification" class="notification is-hidden mb-2">
            </div>
            <div id="sumup-widget">
            </div>
            <div class="is-flex is-justify-content-center pt-2">
              <a id="btnSumpUpCancel" href="/${I18n.locale}/checkout/payment/cancelled/" class="button is-small is-text">
                ${I18n.t("checkout.cancel")}
              </a>
            </div>
          `;
        }
        busy.classList.add('is-active');
      }
    }
  }

  checkQuantities() {
    event.stopPropagation();

    if(event.target.dataset.step) {
      let that = this;
      let uuid = event.target.dataset.uuid;
      let quantity = parseFloat(event.target.value);
      let step = parseFloat(event.target.dataset.step);
      try {
        let d = Math.ceil(quantity / step);
        quantity = d * step;
        event.target.value = quantity

        if(uuid != '' && quantity != '') {
          Rails.ajax({
            url: `/${this.country.toLowerCase()}-${this.locale}/cart/update`,
            type: "POST",
            data: `uuid=${uuid}&quantity=${quantity}`,
            success: function(data) {
              that.updateCart(data);
            },
            error: function(data) {}
          });
        }
      } catch {

      }
    }
  }

  updateQuantity() {
    event.stopPropagation();

    let that = this;
    var uuid = event.target.dataset.uuid;
    var quantity = event.target.value;

    if(uuid != '' && quantity != '') {
      Rails.ajax({
        url: `/${this.country.toLowerCase()}-${this.locale}/cart/update`,
        type: "POST",
        data: `uuid=${uuid}&quantity=${quantity}`,
        success: function(data) {
          that.updateCart(data);
        },
        error: function(data) {}
      });
    }
  }

  toggleCustomerType() {
    if(event.target.value) {
      if(event.target.value == 'company') {
        this.privateDataTarget.classList.add('is-hidden');
        this.companyDataTarget.classList.remove('is-hidden');
      } else {
        this.companyDataTarget.classList.add('is-hidden');
        this.privateDataTarget.classList.remove('is-hidden');
      }
    }
  }

  toggleInvoiceDirection() {
    if(event.target.type) {
      if(event.target.checked) {
        this.invoice_directionTarget.classList.remove('is-hidden');
      } else {
        this.invoice_directionTarget.classList.add('is-hidden');
      }
    }
  }

  setPaymentMethod() {
    let that = this;
    let radio = event.target;


    switch (radio.value) {
      case "twint":
        this.checkout_paymentTarget.innerHTML = `
          <div class="is-flex is-align-items-center">
            <div class="checkout-twint-logo has-text-centered is-flex mr-2">
              <img src="/images/twint_logo_h_pos_bg.png" class="checkout-twint-logo m-auto">
            </div>
            ${I18n.t('checkout.twint')}
          </div>
        `;
        break;
      case "postfinance":
        this.checkout_paymentTarget.innerHTML = `
          <div class="is-flex is-align-items-center">
            <div class="checkout-postfinance-logo has-text-centered is-flex mr-2">
              <img src="/images/postfinance.png" class="checkout-postfinance-logo m-auto">
            </div>
            ${I18n.t('checkout.postfinance')}
          </div>
        `;
        break;
      default:
        var span = radio.parentNode.querySelector('span');
        this.checkout_paymentTarget.innerHTML = span.innerHTML;
    }

    Rails.ajax({
      url: `/${this.country.toLowerCase()}-${this.locale}/checkout/payment/set/${radio.value}`,
      type: "POST",
      data: null,
      success: function(data) {
        if(radio.dataset.chargesType && radio.dataset.chargesAmount) {
          that.applyCharges(radio.dataset.chargesAmount, radio.dataset.chargesType, radio.dataset.chargesDescription)
        } else {
          that.removeCharges()
        }
      },
      error: function(data) {}
    });
  }

  switchCountry() {
    let country = event.target.dataset.country;
    let locale = event.target.dataset.locale;
    if(event.target.dataset.locale) {
      locale = event.target.dataset.locale;
    }
    let title = I18n.t('cart.messages.country_change');
    let content = I18n.t('cart.messages.country_clear_cart', {country: I18n.t(`countries.${country}`)});

    let modal = Modal.createModal(title, content, [{label: I18n.t('btn.cancel'), event: 'cancel', classes: ''}, {label: I18n.t('btn.accept'), event: 'success', classes: 'is-success'}]);
    modal.openModal();

    modal.addEventListener('success', () => {
      Rails.ajax({
        url: `/country/${country}/${locale}`,
        type: "POST",
        data: null,
        success: function(data) {
        },
        error: function(data) {}
      });
    })
  }

  addVoucher() {
    let that = this;
    if(this.vouchercodeTarget.value != '') {
      Rails.ajax({
        url: `/${this.country.toLowerCase()}-${this.locale}/cart/voucher`,
        type: "POST",
        data: `code=${this.vouchercodeTarget.value}`,
        success: function(data) {
          location.reload();
        },
        error: function(data) {

          switch(data.error) {
            case 'purchase':
              var alert = document.querySelector(".notification.voucher");
              alert.innerHTML = `<i class='fa fa-exclamation-circle fa-lg'></i> ${I18n.t('voucher.error.purchase', {purchase: `${data.value} ${that.currency}`})}`;
              alert.classList.remove('is-hidden');
              main.fadeOut(alert, 8000);
              break
            default:
              var alert = document.querySelector(".notification.voucher");
              alert.innerHTML = `<i class='fa fa-exclamation-circle fa-lg'></i> ${I18n.t('voucher.error.invalid')}`;
              alert.classList.remove('is-hidden');
              main.fadeOut(alert, 8000);
              break
          }
        }
      });
    }
  }

  removeVoucher() {
    if(event.target.dataset.code && event.target.dataset.uuid) {
      Rails.ajax({
        url: `/${this.country.toLowerCase()}-${this.locale}/cart/voucher`,
        type: "DELETE",
        data: `code=${event.target.dataset.code}&uuid=${event.target.dataset.uuid}`,
        success: function(data) {
          location.reload();
        },
        error: function(data) {
          location.reload();
        }
      });
    }
  }

  applyCharges(amount, type, description) {
    let that = this;
    Rails.ajax({
      url: `/${this.country.toLowerCase()}-${this.locale}/checkout/charges`,
      type: "POST",
      data: JSON.stringify({type: type, amount: amount, description: description}),
      contentType: false,
      processData: false,
      success: function(data) {
        that.updateCart(data);
        that.checkoutChargesTarget.classList.remove('is-hidden');
      },
      error: function(data) {
      }
    });
  }

  removeCharges() {
    let that = this;
    Rails.ajax({
      url: `/${this.country.toLowerCase()}-${this.locale}/checkout/charges`,
      type: "DELETE",
      data: null,
      success: function(data) {
        that.checkoutChargesTarget.classList.add('is-hidden');
        that.updateCart(data);
      },
      error: function(data) {
      }
    });
  }

  setShippingCountry() {
    let that = this;
    Rails.ajax({
      url: `/${this.country.toLowerCase()}-${this.locale}/cart/shipping_country`,
      type: "POST",
      data: `shipping_country=${event.target.value}`,
      success: function(data) {
        that.updateCart(data);
      },
      error: function(data) {}
    });
  }

  signin() {
    this.signinModalTarget.classList.add("is-active");

    this.signinModalTarget.classList.remove("is-hidden");
    setTimeout(() => {
      this.signinModalTarget.querySelector("#customer_email").focus();
    }, 250)
  }

  closeSignin() {
    this.signinModalTarget.classList.remove("is-active")
    this.signinModalTarget.querySelectorAll("input").forEach((inp, i) => {
      inp.value = ""
    });
  }
}
